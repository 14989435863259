import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Contact from '../views/Contact.vue'
import About from '../views/About.vue'
import Pricing from '../views/Pricing.vue'
import Register from '../views/Register.vue'
import Testimonials from '../views/Testimonials.vue'

import Supplier from '../views/Supplier.vue'
import Shop from '../views/Shop.vue'
import Repairer from '../views/Repairer.vue'
import Insurer from '../views/Insurer.vue'

import Articles from '../views/Articles.vue'
import Article from '../views/Article.vue'

import PricingSimulation from '../views/PricingSimulation.vue'

// import SelfCare from '../views/SelfCare.vue'
// import Shop from '../views/Shop.vue'
// import Supplier from '../views/Supplier.vue'
// import RepairStation from '../views/RepairStation.vue'
// import Districloud from '../views/Districloud.vue'
// import Solutions from '../views/Solutions.vue'

import PageNotFound from '../views/PageNotFound.vue'

// import Tree from '../views/old/Tree.vue'

Vue.use(VueRouter)

const routes = [
    {path: '/', name: 'home', component: Home},
    {path: '/contact', name: 'contact', component: Contact},
    {path: '/a-propos', name: 'about', component: About},
    {path: '/tarifs', name: 'pricing', component: Pricing},
    {path: '/inscription', name: 'register', component: Register},
    {path: '/temoignages', name: 'testimonials', component: Testimonials},
    // {path: '/tree', name: 'tree', component: Tree},
    
    {path: '/fournisseur', name: 'supplier', component: Supplier},
    {path: '/distributeur', name: 'shop', component: Shop},
    {path: '/reparateur', name: 'repairer', component: Repairer},
    {path: '/assureur', name: 'insurer', component: Insurer},
    // {path: '/districloud', name: 'services.districloud', component: Districloud},
    // {path: '/solutions', name: 'services.solutions', component: Solutions},
    // {path: '/selfcare', name: 'services.selfcare', component: SelfCare},
    // {path: '/magasin', name: 'services.shop', component: Shop},
    // {path: '/fournisseur', name: 'services.supplier', component: Supplier},
    // {path: '/station-reparation', name: 'services.repairStation', component: RepairStation},

    {path: '/tarifs/simulation/:token', name: 'pricing.simulator', component: PricingSimulation},

    {path: '/blog/articles', name: 'blog.articles', component: Articles},
    {path: '/blog/article/:slug', name: 'blog.article', component: Article},

    // 404
    { path: "*", component: PageNotFound },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    let removeWarn = {
        to: to,
        from: from
    }
    console.log(removeWarn);
    window.scrollTo(0, 0);
    next()
})

export default router
