<template>
    <div>
        <sav-template>
            <modal name="modal-simulation" height="auto" width="70%" :scrollable="true">
                <div class="p-10" style="z-index: 999 !important;">
                    <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                        <h2 class="font-bold">
                            Calculer le coût
                        </h2>
                        <button @click="$modal.hide('modal-simulation')">
                            <i class="fas fa-times text-red-500"></i>
                        </button>
                    </div>
                    <div>
                        <label class="font-light">Saisir votre adresse email pour accéder au simulateur</label>
                        <input v-model="contactEmailInp" type="text" class="mt-1 border px-4 py-2 w-full text-sm focus:outline-none rounded" />
                        <button :disabled="loadSendMail" @click="validEmail" class="mt-4 px-4 py-2 bg-emerald-600 text-white rounded text-sm">
                            <template v-if="!loadSendMail">
                                Valider <i class="fas fa-check ml-1"></i>
                            </template> 
                            <spinner :size="10" v-if="loadSendMail" />
                        </button>
                    </div>
                </div>
            </modal>

            <div class="px-3 md:px-0 mt-28 container mx-auto">
                <div class="grid grid-cols-2 gap-4">
                    <div class="border rounded p-4" v-for="(formule, formuleIndex) in formules" :key="'formule-' + formuleIndex">
                        <template v-if="formule.prix == 0">
                            <div class="flex items-center justify-between">
                                <div>
                                    <h2 class="font-bold text-2xl">{{formule.nom}}</h2>
                                    <p class="font-light mt-1">Sur invitation</p>
                                </div>
                                <div class="text-emerald-600 text-white px-4 py-2 rounded text-lg">
                                    Gratuite
                                </div>
                            </div>
                        </template>
                        <template v-if="formule.prix > 0">
                            <div class="flex items-center justify-between">
                                <div>
                                    <h2 class="font-bold text-2xl">{{formule.nom}}</h2>
                                    <p class="font-light mt-1">Sur mesure</p>
                                </div>
                                <div @click="$modal.show('modal-simulation')" class="bg-emerald-600 hover:bg-emerald-700 duration-200 cursor-pointer text-white px-4 py-2 rounded text-lg">
                                    Calculer le coût
                                </div>
                            </div>
                        </template>
                        <div class="border-t-2 mt-4 pt-4"></div>
                        <div class="bg-green-50 text-emerald-700 p-4 rounded mb-4 font-base text-center" v-if="formule.prix > 0">
                            Toute l'offre basique + 
                        </div>
                        <div class="mb-5" v-for="(bloc, blocIndex) in formule.blocs" :key="'formule-bloc-' + blocIndex">
                            <h4 class="font-bold underline mb-1">{{ blocIndex }}</h4>
                            <div class="font-light" v-for="(blocLine, blocLineIndex) in bloc" :key="'formule-bloc-line-' + blocLineIndex">
                                <i class="fas fa-check mr-1"></i> {{ blocLine.nom }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </sav-template>
    </div>
</template>

<script>
import apiService from '@/services/api.service'
export default {
    data() {
        return {
            loader: false,
            formules: [],
            actorType: 'Distributeur',

            contactEmailInp: null,
            loadSendMail: false,
        }
    },
    methods: {
        async getModules() {
            this.loader = true
            this.formules = []
            let response = await apiService.post('vitrine.tarifs.get', {
                type: 'Fournisseur',
                no_user: 1
            })
            this.formules = response.data.posts.post

            this.loader = false
        },

        async validEmail() {
            const validateEmail = (email) => {
                return String(email)
                    .toLowerCase()
                    .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    );
            }

            if (validateEmail(this.contactEmailInp)) {
                this.loadSendMail = true
                let response = await apiService.post('vitrine.devis.create-simulation', {
                    email: this.contactEmailInp,
                    no_user: 1
                })
                this.loadSendMail = false
                if (response.data.posts.post == 1) {
                    this.$toast.success('Un email vous a été envoyé')
                    this.contactEmailInp = null
                    this.$modal.hide('modal-simulation')
                } else {
                    this.$toast.success('Une erreur est survenue')
                }
            } else {
                this.$toast.error('Merci de saisir une adresse email correcte')
            }
        }
    },
    mounted () {
        this.getModules()
    },
}
</script>

<style lang="scss" scoped>

</style>