<template>
    <div>
        <sav-template>
            <div class="flex h-screen justify-center items-center">
                <div class="text-center">
                    <h1 class="text-2xl font-bold text-slate-800">Cette page est en cours de développement</h1>
                    <p class="mt-2 font-thin text-slate-600">Pour plus d'informations, merci de contacter contact@savlog.net</p>
                </div>
            </div>
        </sav-template>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>

</style>